<template>
  <div>
    <flixDashboard>
      <template v-slot:content>
        <flixCheckPermissions role="customer" permission="2" error>
            <div :key="loaded">
            <div class="flix-container" v-if="loaded && entry">
              <div class="flix-row flix-form-group">
                <div class="flix-col-md-4" >
                  <div class="flix-form-group">
                    <booking :entry="entry"/>
                  </div>
                  <div class="flix-form-group flix-text-right" >
                    <bookingDelete :index="index" :entry="entry" />
                  </div>
                  <div class="flix-form-group" >
                    <div v-if="entry.status == 'commitment'">
                      <pdfDownload :className='{"flix-btn flix-btn-xs flix-btn-default flix-btn-block": true}' :entry="[entry]" style="margin: 5px 0">{{ $t('message.pdfExport') }}</pdfDownload>
                      <icalDownload :className='{"flix-btn flix-btn-default flix-btn-block flix-btn-xs": true}' :entry="entry"/>
                      <csvDownload :className='{"flix-btn flix-btn-default flix-btn-block flix-btn-xs": true}' :form="entry.form"/>
                    </div>
                  </div>
                  <div class="flix-form-group" >
                    <notes :entry="entry"/>
                  </div>
                </div>
                <div class="flix-col-md-8">
                  <div class="flix-form-group" v-if="webinar">
                    <div class="flix-panel flix-panel-danger">
                      <div class="flix-panel-heading">
                        {{ $tc('message.webinar', 1) }}
                      </div>
                      <div class="flix-panel-body">
                        <webinarStart  :webinar="webinar" :user="'moderator'" :username="$store.getters.user.first_name + ' ' + $store.getters.user.last_name" />
                      </div>
                    </div>
                  </div>
                  <div class="flix-form-group">
                    <div class="flix-panel flix-panel-info" v-if="webinar">
                      <div class="flix-panel-heading">
                        {{ $t('message.userChat') }}
                      </div>
                    </div>
                    <div class="flix-clearfix" />
                    <div class="flix-form-group">
                      <chat :key="$i18n.locale + chatID" :user="$route.params.user" :form="$route.params.form" :entryID="$route.params.entryID"></chat>
                    </div>
                    <div class="flix-clearfix" />
                    <div class="flix-form-group">
                      <deleteChat :settings="{user: $route.params.user, form: $route.params.form, entryID: $route.params.entryID}" :callback="updateLoaded" class="flix-pull-right" />
                    </div>
                    <div class="flix-clearfix" />
                  </div>
                </div>
              </div>
            </div>
            <div class="flix-container" v-else-if="!loaded"><flixLoader /></div>
            <div class="flix-container" v-else><div class="flix-alert flix-alert-danger">{{ $tc('message.noEntry', 1) }}</div> <a href="#" class="flix-html-a" @click.prevent="setReload()"><flixIcon id="refresh" /> {{ $t('message.reload') }}</a></div>
          </div>
        </flixCheckPermissions>
      </template>
    </flixDashboard>
  </div>
</template>

<script>
import md5 from 'js-md5'

export default {
  components: {
    bookingDelete () { return import('@/components/booking/delete') },
    chat () { return import('@/components/chat/userChat') },
    booking () { return import('@/components/booking') },
    notes () { return import('@/components/notes') },
    icalDownload () { return import('@/components/ical') },
    pdfDownload () { return import('@/components/crm/pdf_download') },
    csvDownload () { return import('@/components/csv') },
    webinarStart () { return import('@/components/webinars/jitsi/start') },
    deleteChat () { return import('@/components/chat/delete') }
  },
  data () {
    return {
      entry: false,
      index: false,
      loaded: false,
      webinar: false,
      chatID: 0
    }
  },
  methods: {
    updateLoaded () {
      this.chatID = new Date().getTime()
    },
    getWebinarData () {
      this.webinar = false
      var assistent = this.$store.getters.assistentsByID
      assistent = assistent[this.$route.params.form]
      if (typeof assistent.webinar === 'undefined' || !assistent.webinar) {
        return false
      }
      if (this.entry.status !== 'commitment') {
        return false
      }
      var data = {}
      data.ID = this.getMeetingID()
      data.begin = this.entry.begin
      data.end = this.entry.end
      data.title = assistent.title
      this.webinar = data
    },
    getMeetingID () {
      var ID = ''
      ID += this.$route.params.user
      ID += this.$route.params.form
      ID += this.getDate(this.entry.begin)
      ID += this.getDate(this.entry.end)
      return ID
    },
    getDate (d) {
      d = this.$createDate(d)
      return d.getFullYear() + '' + this.$getNullsBefore(d.getMonth() + 1) + '' + this.$getNullsBefore(d.getDate()) + '' + this.$getNullsBefore(d.getHours()) + '' + this.$getNullsBefore(d.getMinutes())
    },
    setReload () {
      this.loaded = false
      setTimeout(function () {
        this.getEntry()
      }.bind(this), 2000)
    },
    getEntry () {
      if (this.entry) {
        this.loaded = true
        return this.entry
      }
      if (!this.$store.getters.bookings || !Object.keys(this.$store.getters.bookings).length || typeof this.$store.getters.bookings[this.$route.params.form] !== 'object') {
        this.loaded = true
        return false
      }

      for (var i = 0; i < this.$store.getters.bookings[this.$route.params.form].length; i++) {
        if (typeof this.$store.getters.bookings[this.$route.params.form][i] === 'object' && md5(this.$store.getters.bookings[this.$route.params.form][i].ID + '') === this.$route.params.entryID) {
          this.entry = this.$store.getters.bookings[this.$route.params.form][i]
          this.index = i
          this.loaded = true
          this.getWebinarData()
          break
        }
      }
      this.loaded = true
    }
  },
  mounted () {
    this.setReload()
  }
}
</script>
